import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./UniqueSection.css";

gsap.registerPlugin(ScrollTrigger);

const features = [
  {
    title: "Faculty Quality",
    description:
      "Credentials, experience, and teaching methodologies of the faculty members.",
    image: "https://via.placeholder.com/300x200",
  },
  {
    title: "Placement Records",
    description:
      "Success rate of campus placements, median salary packages, and partnerships with reputed companies.",
    image: "https://via.placeholder.com/300x200",
  },
  {
    title: "Affordability",
    description:
      "Tuition fees, scholarship opportunities, and the cost of living, including hostel or housing expenses.",
    image: "https://via.placeholder.com/300x200",
  },
  {
    title: "Alumni Network",
    description:
      "Strength and involvement of the alumni in mentoring, networking, and job placements.",
    image: "https://via.placeholder.com/300x200",
  },
  {
    title: "Industry Exposure",
    description:
      "Opportunities for internships, industry visits, guest lectures, and real-world projects.",
    image: "https://via.placeholder.com/300x200",
  },
  {
    title: "Campus Life",
    description:
      "Vibrancy of student life, cultural activities, clubs, and overall campus atmosphere.",
    image: "https://via.placeholder.com/300x200",
  },
];

const UniqueFeatures = () => {
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const totalWidth =
        wrapperRef.current.scrollWidth - containerRef.current.offsetWidth;

      gsap.to(wrapperRef.current, {
        x: -totalWidth,
        ease: "none",
        scrollTrigger: {
          trigger: containerRef.current,
          pin: true,
          scrub: 1,
          start: "top top",
          end: `+=${totalWidth}`,
        },
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <div className="unique-features-container" ref={containerRef}>
      <h1 className="heading">WHAT MAKES APEX UNIQUE?</h1>
      <h2 className="subheading">Your Search for World Class Institute Ends Here.</h2>
      <div className="unique-feature-wrapper" ref={wrapperRef}>
        {features.map((feature, index) => (
          <div className="unique-feature-box" key={index}>
            <img src={feature.image} alt={feature.title} className="feature-image" />
            <div className="feature-overlay">
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniqueFeatures;
