import React, { useEffect, useState, useRef } from 'react';
import './Facts.css'; 

const Facts = () => {
    const facts = [
        { title: 'COMPANIES', target: 80, icon: '🏢', subHeading: 'For campus placements in APEX' },
        { title: 'PLACEMENTS', target: 500, icon: '🎓', subHeading: 'Offered in APEX Institute' },
        { title: 'ENGINEERING', target: 8, icon: '⚙️', subHeading: 'Heighest Package Offered', superscript: 'LPA' },
        { title: 'MANAGEMENT', target: 12, icon: '📈', subHeading: 'Heighest Package Offered', superscript: 'LPA' },
    ];

    return (
        <div className="facts-container">
            {facts.map((fact, index) => (
                <CountdownFact 
                    key={index} 
                    title={fact.title} 
                    target={fact.target} 
                    icon={fact.icon} 
                    subHeading={fact.subHeading} 
                    superscript={fact.superscript} 
                />
            ))}
        </div>
    );
};

const CountdownFact = ({ title, target, icon, subHeading, superscript }) => {
    const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true); 
                        observer.unobserve(ref.current);
                    }
                });
            },
            { threshold: 1.0 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current); 
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            let start = 0; 
            const end = target; 
            const duration = 2000; 
            const incrementTime = duration / end; 

            const timer = setInterval(() => {
                if (start < end) {
                    start++;
                    setCount(start);
                } else {
                    clearInterval(timer); 
                }
            }, incrementTime);

            return () => clearInterval(timer); 
        }
    }, [isVisible, target]);

    return (
        <div className="countdown-fact" ref={ref}>
            <div className="countdown-card">
                <div className="fact-icon">{icon}</div>
                <h1 className="fact-number">
                    {count}{target === 80 || target === 500 ? '+' : ''}
                    {superscript && <sup>{superscript}</sup>}
                </h1>
                <h2 className="fact-title">{title}</h2>
                <h3 className="fact-subheading">{subHeading}</h3>
            </div> 
        </div>
    );
};

export default Facts;
