import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebase"; // Import Firestore and Storage
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import "./NoticeUpdate.css";

const NoticeUpdate = () => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notices, setNotices] = useState([]);
  const [editId, setEditId] = useState(null);

  // Fetch notices from Firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "notices"), (snapshot) => {
      const noticesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotices(noticesData);
    });
    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let fileUrl = link; // Default to link input if no file is uploaded
      if (file) {
        const storageRef = ref(storage, `notices/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        await uploadTask;
        fileUrl = await getDownloadURL(uploadTask.snapshot.ref); // Uploaded file URL
      }

      if (editId) {
        // Update existing notice
        const noticeDoc = doc(db, "notices", editId);
        await updateDoc(noticeDoc, { title, linkOrPdf: fileUrl });
        toast.success("Notice updated successfully!"); // Show success toast
        setEditId(null);
      } else {
        // Add new notice
        await addDoc(collection(db, "notices"), {
          title,
          linkOrPdf: fileUrl,
          createdAt: Timestamp.now(),
        });
        toast.success("Notice added successfully!"); // Show success toast
      }

      // Reset the form
      setTitle("");
      setLink("");
      setFile(null);
    } catch (error) {
      console.error("Error handling notice:", error);
      toast.error("Failed to handle notice."); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (notice) => {
    setEditId(notice.id);
    setTitle(notice.title);
    setLink(notice.linkOrPdf);
    setFile(null); // Clear file input for edit mode
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this notice?")) {
      try {
        await deleteDoc(doc(db, "notices", id));
        toast.success("Notice deleted successfully!"); // Show success toast
      } catch (error) {
        console.error("Error deleting notice:", error);
        toast.error("Failed to delete notice."); // Show error toast
      }
    }
  };

  return (
    <div className="notice-container-unique">
      <form onSubmit={handleSubmit} className="notice-form-unique">
        <h2>{editId ? "Edit Notice" : "Add Notice"}</h2>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="notice-input-unique"
        />
        <input
          type="url"
          placeholder="Insert Link (optional if uploading a file)"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="notice-input-unique"
        />
        <input
          type="file"
          accept="application/pdf"
          onChange={(e) => setFile(e.target.files[0])}
          className="notice-file-unique"
        />
        <button type="submit" disabled={loading} className="notice-submit-btn-unique">
          {loading ? "Processing..." : editId ? "Update Notice" : "Add Notice"}
        </button>
      </form>

      <div className="notice-list-unique">
        <h2>All Notices</h2>
        {notices.map((notice) => (
          <div key={notice.id} className="notice-item-unique">
            <span className="notice-title-unique">{notice.title}</span>
            <div className="notice-actions-unique">
              <button
                className="edit-btn-unique"
                onClick={() => handleEdit(notice)}
                title="Edit Notice"
              >
                ✏️ Edit
              </button>
              <button
                className="delete-btn-unique"
                onClick={() => handleDelete(notice.id)}
                title="Delete Notice"
              >
                ❌ Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* ToastContainer to display notifications */}
      <ToastContainer />
    </div>
  );
};

export default NoticeUpdate;
