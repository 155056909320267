
import { Routes ,Route } from 'react-router-dom';
import './App.css';
import TopBar from './Comonents/Navbar/Topbar/Topbar';
import Navbar from './Comonents/Navbar/Navbar';
import Home from './Comonents/Home/Home';
import LoginRegister from './Comonents/LoginRegister/LoginRegister';
import NoticeUpdate from './Comonents/Notice/NoticeUpdate/NoticeUpdate';
import NoticeBoard from './Comonents/Notice/NoticeBoard/NoticeBoard';
import Gallery from './Comonents/Gallery/Gallery';
import UploadImages from './Comonents/Gallery/UploadImages';
import GrievanceSection from './Comonents/GrievanceSection/GrievanceSection';
import GrievanceUpload from './Comonents/GrievanceSection/GrievanceUpload';
import Disclosure from './Comonents/Disclosure/Disclosure';
import DisclosureUpload from './Comonents/Disclosure/DisclosureUpload';
import AICTE from './Comonents/AICTE/AICTE';
import AICTEUpload from './Comonents/AICTE/AICTEUpload';
import AllEOA from './Comonents/AllEOA/AllEOA';
import AllEOAUpload from './Comonents/AllEOA/AllEOAUpload';
import Footer from './Comonents/Footer/Footer';
import Modal from './Comonents/PopUp/Modal';
import ModalUpload from './Comonents/PopUp/ModalUpload';


function App() {
  
  return (
    <div className="App">

        <TopBar />
        <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginRegister />} />
        <Route path="/updatenotice" element={<NoticeUpdate />} />
        <Route path="/notice" element={<NoticeBoard />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery-upload" element={<UploadImages/>} />
        <Route path="/grievance" element={<GrievanceSection/>} />
        <Route path="/grievance-upload" element={<GrievanceUpload/>} />
        <Route path="/disclosure" element={<Disclosure/>} />
        <Route path="/disclosure-upload" element={<DisclosureUpload/>} />
        <Route path="/aicte" element={<AICTE/>} />
        <Route path="/aicte-upload" element={<AICTEUpload/>} />
        <Route path="/alleoa" element={<AllEOA/>} />
        <Route path="/alleoa-upload" element={<AllEOAUpload/>} />
       
        <Route path="/modal-upload" element={<ModalUpload/>} />


        












      </Routes>
      <Footer/>

    </div>
  );
}

export default App;
