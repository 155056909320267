import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Import Firestore
import { collection, onSnapshot } from 'firebase/firestore';
import './Gallery.css'; // Custom CSS for styling

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'gallery'), (snapshot) => {
      const imagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imagesData);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      {/* Hero Section */}
      <div className="g-hero-section">
        <div className="g-hero-content">
          <h1 className="g-hero-title">Welcome Apex Gallery</h1>
          <p className="g-hero-subtitle">
          Explore a curated collection of memorable moments and significant events, thoughtfully presented.
          </p>
       
        </div>
      </div>

      {/* Gallery Section */}
      <div className="gallery-container">
    
        <div className="gallery-masonry">
          {images.map((image) => (
            <div key={image.id} className="gallery-item">
              <img
                src={image.imageUrl}
                alt={image.title}
                className="gallery-image"
                loading="lazy"
              />
              <div className="gallery-title">{image.title}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
