import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase'; // Import Firestore from firebase.js
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import './NoticeBoard.css'; // Add custom CSS for styling

const NoticeBoard = () => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    // Fetch notices from Firestore
    const q = query(collection(db, 'notices'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const noticesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotices(noticesData);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="notice-board">
      <div className="notice-container">
        {notices.map((notice) => {
          // Check if the notice is new (within the last 5 days)
          const isNew = (Date.now() - notice.createdAt.toMillis()) / (1000 * 60 * 60 * 24) <= 5;

          return (
            <div
              key={notice.id}
              className="notice-item"
              onClick={() => window.open(notice.linkOrPdf, '_blank')}
            >
              <span className="notice-heading">
                {notice.title}
                {isNew && <sup className="new-badge">New</sup>}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NoticeBoard;
