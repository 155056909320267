import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

import "./Disclosure.css";

const Disclosure = () => {
  const [files, setFiles] = useState([]);

  // Fetch files from Firestore
  const fetchFiles = async () => {
    const querySnapshot = await getDocs(collection(db, "disclosure"));
    const filesData = querySnapshot.docs.map((doc, index) => ({
      id: doc.id,
      ...doc.data(),
      index: index + 1, // Add serial number
    }));
    setFiles(filesData);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <div className="disclosure-section">
      <div className="grievance-hero">
        <h1 className="grievance-title">MANDATORY DISCLOSURE</h1>
        <p className="grievance-description">
        Below are the mandatory disclosure details to ensure transparency and compliance.
        </p>
      </div>

     

      <table className="grievance-table">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>File Name</th>
            <th>Download Link</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file.id}>
              <td>{file.index}</td>
              <td>{file.fileName}</td>
              <td>
                <a href={file.downloadURL} target="_blank" rel="noopener noreferrer">
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Disclosure;
