import React from 'react';
import './TopBar.css';
import { Link } from 'react-router-dom';

const TopBar = () => {
    return (
        <div className="topbar">
            <div className="topbar-left">
           <Link to="/">  <img src="/img/apex_logo.png" alt="Apex Logo" className="topbar-logo" /></Link>   
                <div className="topbar-heading">
                    <h1 className="topbar-title">Apex Group of Institutions</h1>
                    <p className="topbar-subtitle">
                        B.TECH. | POLYTECHNIC | MBA | BBA | BCA | B.COM. (HONS.) | B.ED. | 
                        D.PHARM. | B.PHARM. | B.Sc.(PCM/Home Sc.)
                    </p>
                </div>
            </div>
            <div className="topbar-right">
                <p className="topbar-contact">
                    📞 +91-9876543210
                </p>
                <p className="topbar-email">
                    ✉️ contact@apex.edu
                </p>
            </div>
        </div>
    );
};

export default TopBar;
