import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Disable scrolling when menu is open
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    }, [isOpen]);

    return (
        <nav className="navbar">
            <div className="navbar-container">
              <Link to="/">  <img src="/img/apex_logo.png" alt="Apex Logo" className="navbar-logo" /></Link>
                <div className="navbar-heading">
                    <h1 className="navbar-title">Apex Group of Institutions</h1>
                    <p className="navbar-subtitle">
                        B.TECH. | POLYTECHNIC | MBA | BBA | BCA | B.COM. (HONS.) | B.ED. | 
                        D.PHARM. | B.PHARM. | B.Sc.(PCM/Home Sc.)
                    </p>
                </div>
                <button className="menu-toggle" onClick={toggleMenu}>
                    <span className={isOpen ? "menu-bar open" : "menu-bar"}></span>
                    <span className={isOpen ? "menu-bar open" : "menu-bar"}></span>
                    <span className={isOpen ? "menu-bar open" : "menu-bar"}></span>
                </button>
                <ul className={`nav-links ${isOpen ? "open" : ""}`}>
                    <li><a href="#about" className="nav-link">ABOUT</a></li>
                    <li><a href="#programs" className="nav-link">PROGRAMS</a></li>
                    <li><a href="#academics" className="nav-link">ACADEMICS</a></li>
                    <li><a href="#admissions" className="nav-link">ADMISSIONS</a></li>
                    <li><a href="#placements" className="nav-link">PLACEMENTS</a></li>
                    <li><a href="/aicte" className="nav-link">AICTE FEEDBACK FACILITY</a></li>
                    <li><a href="/gallery" className="nav-link">GALLERY</a></li>
                    <li><a href="/disclosure" className="nav-link">MANDATORY DISCLOSURE</a></li>
                    <li><a href="/grievance" className="nav-link">GRIEVANCE</a></li>

                    <li><a href="#contact" className="nav-link">CONTACT US</a></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
