import React, { useState, useEffect } from "react";
import { storage, db } from "../../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, addDoc, getDocs, deleteDoc, doc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./DisclosureUpload.css";

const DisclosureUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);

  // Fetch files from Firestore
  const fetchFiles = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "disclosure"));
      const filesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFiles(filesData);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  // Handle file upload
  const handleFileUpload = async (e) => {
    e.preventDefault();

    if (!selectedFile) {
      toast.error("Please select a file to upload!");
      return;
    }

    setUploading(true);
    const fileRef = ref(storage, `disclosure/${selectedFile.name}`);
    try {
      // Upload the file to Firebase Storage
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);

      // Add file metadata to Firestore
      await addDoc(collection(db, "disclosure"), {
        fileName: selectedFile.name,
        downloadURL,
      });

      toast.success("File uploaded successfully!");
      setSelectedFile(null);
      fetchFiles(); // Refresh the file list
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
      console.error("Upload Error:", error);
    } finally {
      setUploading(false);
    }
  };

  // Handle file deletion
  const handleDeleteFile = async (fileId, fileName) => {
    const fileRef = ref(storage, `disclosure/${fileName}`);
    try {
      // Delete the file from Firebase Storage
      await deleteObject(fileRef);

      // Remove file metadata from Firestore
      await deleteDoc(doc(db, "disclosure", fileId));

      toast.success("File deleted successfully!");
      fetchFiles(); // Refresh the file list
    } catch (error) {
      toast.error("Error deleting file. Please try again.");
      console.error("Delete Error:", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <div className="grievance-upload-container">
      <ToastContainer />
      <h2 className="grievance-upload-title">MANDATORY DISCLOSURE File Management</h2>
      
      {/* Upload Form */}
      <form onSubmit={handleFileUpload} className="grievance-upload-form">
        <label htmlFor="fileUpload" className="grievance-upload-label">
          Select PDF to Upload:
        </label>
        <input
          type="file"
          id="fileUpload"
          accept="application/pdf"
          onChange={(e) => setSelectedFile(e.target.files[0])}
          className="grievance-upload-file-input"
        />
        <button type="submit" disabled={uploading} className="grievance-upload-button">
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </form>

      {/* Files List */}
      {files.length > 0 && (
        <div className="grievance-upload-file-list">
          <h3 className="grievance-upload-file-list-title">Uploaded Files</h3>
          <ul className="grievance-upload-file-list-items">
            {files.map((file, index) => (
              <li key={file.id} className="grievance-upload-file-item">
                <span className="grievance-upload-file-name">{index + 1}. {file.fileName}</span>
                <div className="grievance-upload-file-actions">
                  <a
                    href={file.downloadURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="grievance-upload-download-link"
                  >
                    Download
                  </a>
                  <button
                    className="grievance-upload-delete-button"
                    onClick={() => handleDeleteFile(file.id, file.fileName)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DisclosureUpload;
