import React, { useEffect, useState, Suspense, lazy } from 'react';
import { db } from '../../firebase'; // Import Firestore
import { collection, onSnapshot } from 'firebase/firestore';
import './Modal.css';

// Lazy-loaded Image Component
const LazyImage = lazy(() => import('./LazyImage')); // Dynamically import LazyImage

const Modal = () => {
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'modal'), (snapshot) => {
      const imagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imagesData);
    });

    const timer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => {
      unsubscribe();
      clearTimeout(timer);
    };
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={closeModal}>
          &times;
        </button>
       
        <div className="modal-masonry">
          <Suspense fallback={<div>Loading images...</div>}>
            {images.map((image) => (
              <div key={image.id} className="modal-item">
                <LazyImage
                  src={image.imageUrl}
                  alt={image.title}
                  className="modal-image"
                />
             
              </div>
            ))}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Modal;
