import React, { useState, useEffect } from "react";
import { storage, db } from "../../firebase"; // Import Firestore and Storage
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, addDoc, deleteDoc, doc, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UploadImages.css"; 

const UploadImages = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]); 

 
  useEffect(() => {
    const fetchImages = async () => {
      const imagesCollection = collection(db, "gallery");
      const imageSnapshot = await getDocs(imagesCollection);
      const imageList = imageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imageList);
    };

    fetchImages();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file || !title) return; // Ensure both file and title are provided

    setLoading(true);
    const storageRef = ref(storage, `gallery/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await uploadTask;
      const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
      const newImage = {
        imageUrl: fileUrl,
        title: title,
        createdAt: new Date(),
      };

      const docRef = await addDoc(collection(db, "gallery"), newImage);

      // Add the uploaded image to the state
      setImages((prevImages) => [
        ...prevImages,
        { id: docRef.id, ...newImage }, // Use Firestore document ID
      ]);
      toast.success("Image uploaded successfully!");
      setFile(null);
      setTitle(""); // Clear title after successful upload
    } catch (error) {
      toast.error("Error uploading image.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (imageId, imageUrl) => {
    try {
      // Split the URL to extract the file path and decode it
      const path = decodeURIComponent(imageUrl.split('gallery%2F')[1].split('?')[0]);
  
      // Create a reference to the image in Firebase Storage
      const imageRef = ref(storage, `gallery/${path}`);
  
      // Delete the image from Firebase Storage
      await deleteObject(imageRef);
  
      // Delete the image document from Firestore
      await deleteDoc(doc(db, "gallery", imageId));
  
      // Remove image from local state
      setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
  
      toast.success("Image deleted successfully!");
    } catch (error) {
      toast.error("Error deleting image.");
    }
  };
  
  

  return (
    <div className="upload-gallery-container">
      <h2>Upload Image</h2>
      <div className="upload-form">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <input
          type="text"
          placeholder="Enter Image Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <button onClick={handleUpload} disabled={loading}>
          {loading ? "Uploading..." : "Upload Image"}
        </button>
      </div>

      <div className="uploaded-images">
        {images.length > 0 && (
          <div className="gallery-u">
            {images.map((image) => (
              <div className="gallery-item-u" key={image.id}>
                <img src={image.imageUrl} alt={image.title} className="gallery-image-u" />
                <div className="gallery-title-u">
                  <span>{image.title}</span>
                </div>
                <button
                  className="delete-button"
                  onClick={() => handleDelete(image.id, image.imageUrl)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default UploadImages;
