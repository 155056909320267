import React from 'react'
import Carousel from '../Carousel/Carousel'
import Modal from '../PopUp/Modal'
import Facts from '../Countdown/Facts'
import ScholarshipBanner from './HComponents/ScholarshipBanner/ScholarshipBanner'
import ChairmanMessage from './HComponents/ChairmanMessage/ChairmanMessage'
import UniqueSection from './HComponents/UniqueSection/UniqueSection'


const Home = () => {
  return (
    <div>
      <Modal/>
        <Carousel/>
        <Facts/>
        <ScholarshipBanner/>
        <ChairmanMessage/>

        <UniqueSection/>
        <ChairmanMessage/>

      
    </div>
  )
}
                                                                                                                                
export default Home