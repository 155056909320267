import React, { useState, useEffect } from "react";
import { storage, db } from "../../firebase"; 
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, addDoc, deleteDoc, doc, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ModalUpload.css";

const ModalUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]); 

  useEffect(() => {
    const fetchImages = async () => {
      const imagesCollection = collection(db, "modal");
      const imageSnapshot = await getDocs(imagesCollection);
      const imageList = imageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setImages(imageList);
    };

    fetchImages();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) return; // Ensure a file is selected

    setLoading(true);
    const storageRef = ref(storage, `modal/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await uploadTask;
      const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
      const newImage = {
        imageUrl: fileUrl,
        createdAt: new Date(),
      };

      const docRef = await addDoc(collection(db, "modal"), newImage);

      setImages([{ id: docRef.id, ...newImage }]); // Replace existing images with the new one
      toast.success("Image uploaded successfully!");
      setFile(null);
    } catch (error) {
      toast.error("Error uploading image.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (imageId, imageUrl) => {
    try {
      const path = decodeURIComponent(imageUrl.split("modal%2F")[1].split("?")[0]);
      const imageRef = ref(storage, `modal/${path}`);

      await deleteObject(imageRef);
      await deleteDoc(doc(db, "modal", imageId));

      setImages([]); // Clear the images
      toast.success("Image deleted successfully!");
    } catch (error) {
      toast.error("Error deleting image.");
    }
  };

  return (
    <div className="upload-modal-container">
      <h2>Upload an Image</h2>
      <div className="upload-form">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          disabled={images.length > 0 || loading} 
        />
        <button onClick={handleUpload} disabled={!file || loading || images.length > 0}>
          {loading ? "Uploading..." : "Upload Image"}
        </button>
      </div>

      {images.length > 0 && (
        <div className="uploaded-images">
          {images.map((image) => (
            <div className="modal-item-u" key={image.id}>
              <img src={image.imageUrl} alt="Uploaded" className="modal-image-u" />
              <button
                className="delete-button"
                onClick={() => handleDelete(image.id, image.imageUrl)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default ModalUpload;
