import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './ChairmanMessage.css';

gsap.registerPlugin(ScrollTrigger);

const ChairmanMessage = () => {
    const textRef = useRef(null);

    useEffect(() => {
        const textElements = textRef.current.querySelectorAll('.chairman-fade-text');

        gsap.fromTo(
            textElements,
            { opacity: 0 },
            {
                opacity: 1,
                duration: 0.7,
                ease: 'power2.out',
                stagger: 0.2, // Small stagger for fast sequence
                scrollTrigger: {
                    trigger: textRef.current,
                    start: 'top 80%',
                    end: 'top 20%',
                    toggleActions: 'play none none reset',
                },
            }
        );
    }, []);

    return (
        <div className="chairman-message-container">
            <div className="chairman-image">
                <img
                    src="https://res.cloudinary.com/dj9toibx5/image/upload/v1733727934/R-P-Singh-Chairman-768x509_cjhazb.jpg" /* Replace with actual image URL */
                    alt="Chairman"
                    className="chairman-photo"
                />
            </div>
            <div className="chairman-text" ref={textRef}>
                <h2 className="chairman-heading chairman-fade-text">Message From Chairman</h2>
                <p className="chairman-message-paragraph chairman-fade-text">
                    At Apex Group of Institutions, we believe that we are at a defining point in time as a society—a
                    time that for future generations should look back on us with admiration. <br/>
                    In this “Centerpiece of Our View Book,” we put our aspirations before you and share “Our Vision”
                    for what we believe in shall be possible at the “Apex Group of Institutions.”
                </p>
                <p className="chairman-message-paragraph chairman-fade-text">
                    
                </p>
                
                <p className="chairman-author chairman-fade-text">Er. R.P. SINGH</p>
                <p className="chairman-title chairman-fade-text">CHAIRMAN, APEX GROUP OF INSTITUTIONS</p>
            </div>
        </div>
    );
};

export default ChairmanMessage;
