import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

const Carousel = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Desktop images
  const desktopImages = [
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603273/4_yju61h.webp',
      // heading: 'Elektra Efflux',
      // text: 'At Elektra Efflux, we bring together advanced engineering and cutting-edge technology to provide efficient, reliable water solutions for every industry. From pump systems to treatment panels, our expertise and commitment to quality set the standard for innovation in water management',
      // buttonLabel: 'Learn More',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603268/7_oljuol.webp',
      // heading: 'Elektra Efflux',
      // text: 'Quality isn’t just our promise – it’s our commitment. Elektra Efflux ensures that every solution, from installation to maintenance, meets the highest standards of excellence. Our team’s dedication and expertise make us a leader in reliable, high-performance water solutions.',
      // buttonLabel: 'Explore Now',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603268/5_tjmdbq.webp',
      // heading: 'Elektra Efflux',
      // text: 'Elektra Efflux is more than a provider; we’re your trusted partner in water management solutions. Our experience spans industries, and we’re committed to delivering top-tier products and service that help you operate smoothly and sustainably',
      // buttonLabel: 'Explore Now',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603268/5_tjmdbq.webp',
      // heading: 'Elektra Efflux',
      // text: 'Elektra Efflux is more than a provider; we’re your trusted partner in water management solutions. Our experience spans industries, and we’re committed to delivering top-tier products and service that help you operate smoothly and sustainably',
      // buttonLabel: 'Explore Now',
    },
  ];

  // Mobile images
  const mobileImages = [
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603297/7_xkngep.webp',
      // heading: 'Elektra Efflux',
      // text: 'At Elektra Efflux, we bring together advanced engineering and cutting-edge technology to provide efficient, reliable water solutions for every industry. From pump systems to treatment panels, our expertise and commitment to quality set the standard for innovation in water management',
      // buttonLabel: 'Learn More',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603297/6_ib0qq7.webp',
      // heading: 'Elektra Efflux',
      // text: 'Quality isn’t just our promise – it’s our commitment. Elektra Efflux ensures that every solution, from installation to maintenance, meets the highest standards of excellence. Our team’s dedication and expertise make us a leader in reliable, high-performance water solutions.',
      // buttonLabel: 'Explore Now',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603294/8_y7xi8a.webp',
      // heading: 'Elektra Efflux',
      // text: 'Elektra Efflux is more than a provider; we’re your trusted partner in water management solutions. Our experience spans industries, and we’re committed to delivering top-tier products and service that help you operate smoothly and sustainably',
      // buttonLabel: 'Explore Now',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603293/1_doo4kd.webp',
      // heading: 'Elektra Efflux',
      // text: 'Elektra Efflux is more than a provider; we’re your trusted partner in water management solutions. Our experience spans industries, and we’re committed to delivering top-tier products and service that help you operate smoothly and sustainably',
      // buttonLabel: 'Explore Now',
    },
    {
      src: 'https://res.cloudinary.com/dj9toibx5/image/upload/v1732603293/2_tnxhte.webp',
      // heading: 'Elektra Efflux',
      // text: 'Elektra Efflux is more than a provider; we’re your trusted partner in water management solutions. Our experience spans industries, and we’re committed to delivering top-tier products and service that help you operate smoothly and sustainably',
      // buttonLabel: 'Explore Now',
    },
    
  ];

  // Function to handle screen size change
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Change at 768px (or adjust the breakpoint as needed)
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial screen size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  
  const imagesToUse = isMobile ? mobileImages : desktopImages;

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {imagesToUse.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image.src} alt={`Slide ${index}`} />
            <div className={`carousel-text-overlay ${index === 0 ? 'left' : 'right'}`}>
              <h2>{image.heading}</h2>
              <p>{image.text}</p>
              {/* <button className="carousel-button">{image.buttonLabel}</button> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
