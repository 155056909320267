import React, { useState } from "react";
import { auth } from "../../firebase"; // Import auth from firebase.js
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import "./LoginRegister.css"; // Ensure your CSS file is scoped with unique class names

const LoginRegister = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  // Handle Login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setMessage("Login successful!");
    } catch (error) {
      setMessage(error.message);
    }
  };

  // Handle Registration
  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setMessage("Registration successful!");
    } catch (error) {
      setMessage(error.message);
    }
  };

  // Handle Password Reset
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent.");
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <div className="login-register-container">
      <div className="login-register-form-container">
        <h2 className="login-register-title">{isLogin ? "Login" : "Register"}</h2>
        <form
          onSubmit={isLogin ? handleLogin : handleRegister}
          className="login-register-form"
        >
          <input
            className="login-register-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="login-register-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {!isLogin && (
            <input
              className="login-register-input"
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          )}
          <button className="login-register-button" type="submit">
            {isLogin ? "Login" : "Register"}
          </button>
        </form>
        <p className="login-register-message">{message}</p>
        {isLogin && (
          <button
            className="login-register-forgot-password"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </button>
        )}
        <p className="login-register-toggle">
          {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
          <span
            className="login-register-switch"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? "Register" : "Login"}
          </span>
        </p>
      </div>
      <div className="login-register-image-container">
        <img
          src="https://via.placeholder.com/500"
          alt="Illustration"
          className="login-register-image"
        />
      </div>
    </div>
  );
};

export default LoginRegister;
