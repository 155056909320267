import React from "react";
import "./Footer.css";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Apply Here Section */}
        <div className="footer-column">
          <h3>APPLY HERE</h3>
          <ul>
            <li><a href="#">Online Admission</a></li>
            <li><a href="#">How to Apply?</a></li>
            <li><a href="#">Scholarship</a></li>
          </ul>
        </div>

        {/* Learn Here Section */}
        <div className="footer-column">
          <h3>LEARN HERE</h3>
          <ul>
            <li><a href="#">Our Institutes</a></li>
            <li><a href="#">Teaching Practices</a></li>
            <li><a href="#">Apex Advantages</a></li>
          </ul>
        </div>

        {/* Visit Here Section */}
        <div className="footer-column">
          <h3>IMPORTANT LINKS</h3>
          <ul>
            <li><a href="#">ABOUT</a></li>
            <li><a href="#">PLACEMENTS</a></li>
            <li><a href="#">CONTACT US</a></li>
            <li><a href="#">PROGRAMS</a></li>
            <li><a href="#">ACADMICS</a></li>
            <li><a href="#">ALL EOA</a></li>
            <li><a href="#">AICTE FEEDBACK FACILITY</a></li>
            <li><a href="#">MANDATORY DISCLOSURE</a></li>
            <li><a href="#">ONLINE GRIEVANCE REDRESSAL</a></li>
          

          </ul>
        </div>

        {/* Get in Touch Section */}
        <div className="footer-column">
          <h3>GET IN TOUCH</h3>
          <address>
            <p>
              <FaMapMarkerAlt className="footer-icon" /> Apex Group of Institutions<br />
              National Highway No. 87, Tehsil Bilaspur<br />
              Dist. Rampur, Kaushalganj-244923, Uttar Pradesh
            </p>
            <p>
              <FaPhoneAlt className="footer-icon" /> 
              <a href="tel:+917351408009">+91-7351408009</a>
            </p>
            <p>
              <FaEnvelope className="footer-icon" /> 
              <a href="mailto:contact@apex.ac.in">contact@apex.ac.in</a>
            </p>
            <p>
              <a href="#">Open in Google Maps</a>
            </p>
          </address>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="footer-social">
        <a href="#"><FaFacebookF /></a>
        <a href="#"><FaTwitter /></a>
        <a href="#"><FaInstagram /></a>
        <a href="#"><FaYoutube /></a>
      </div>

      <div className="footer-bottom">
  <p>
    Design & Developed By{" "}
    <a 
      href="https://www.apex.ac.in" 
      target="_blank" 
      rel="noopener noreferrer"
      className="footer-link"
    >
      an Apexian
    </a>
        {" "}
    <a 
      href="https://www.instagram.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="footer-icon-link"
    >
      <FaInstagram className="footer-icon" />
     
    </a>
    &{" "}

    <a 
      href="https://www.anvide.com" 
      target="_blank" 
      rel="noopener noreferrer"
      className="footer-link"
    >
      Team Anvide
    </a>
    
  </p>
  <p>
    © All rights reserved 2024
  </p>
</div>

     
    </footer>
  );
};

export default Footer;
