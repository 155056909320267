import React from 'react';
import './ScholarshipBanner.css';

const ScholarshipBanner = () => {
    return (
        <div className="scholarship-banner-container">
            <div className="scholarship-banner-overlay">
                <div className="scholarship-bubbles">
                    {[...Array(10)].map((_, index) => (
                        <span key={index} className="scholarship-bubble"></span>
                    ))}
                </div>
            </div>
            <div className="scholarship-banner-content">
                <h1 className="scholarship-banner-heading">Avail up to 100% Scholarship</h1>
                <p className="scholarship-banner-subheading">APEX Institute Common Entrance Test</p>
                <button className="scholarship-banner-button">Register Now</button>
            </div>
        </div>
    );
};

export default ScholarshipBanner;
